<template>
  <div class="rule">
    <page-title />
    <div class="rule-box">
      <p class="rule-title">竞赛流程</p>
      <p class="details">
        关注健康江苏公众号——授权登录——开始答题（随机5题）——满分获得抽奖资格——抽奖（获奖会自动发红包，请及时领取）——可分享再获得一次抽奖机会
      </p>
      <p class="rule-title">竞赛规则</p>
      <p class="details">
        1、每人每天最多两次抽奖资格，即答题全对获得1次+分享成功获得1次。
      </p>
      <p class="details">
        2、分享成功标准为：通过本人分享链接初次登录用户完成1次答题。分享方法：点击竞赛页面右上角“…”转发给微信好友或分享到朋友圈。
      </p>
      <p class="details">
        3、每人每天限1次答题机会，答题后才有资格分享，即使未满分，依然可以通过分享成功获得1次抽奖机会。
      </p>
      <p class="details">4、答题抽奖和分享抽奖的资格活动期每日0点清零。</p>
      <p class="details">
        5、如答题未正常完成，答题中或生成成绩页前终止退出，则本日答题资格取消，不影响第二天重新参与活动。
      </p>
      <p class="rule-title">奖项设置</p>
      <p class="details">
        1、对各设区市参加答题人次情况进行统计排名，设置组织奖3个。
      </p>
      <p class="details">
        2、参赛者答题后抽奖有机会获得微信红包
        (请于24小时内及时领取，过期无效)，以示鼓励。
      </p>
      <div class="lucky-rank" @click="$router.replace('/home')">
        <img :src="require('@/assets/icons/rule.png')" alt="" />
        <span>回到首页</span>
      </div>
      <div style="height: 3vh"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Rule',
  data() {
    return {
      rankList: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    getRankList(type) {
      if (type === 'week') {
        return this.$http({
          url: '/xt-exam/etUser/weekRank',
          params: {
            type
          }
        })
      } else {
        return this.$http({
          url: '/xt-exam/etUser/allRank',
          params: {
            type
          }
        })
      }
    },
    init() {
      this.getRankList().then(response => {
        if (response.code == 200) {
          this.rankList = response.data
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.rule {
  display: flex;
  flex-direction: column;
}
.rule-box {
  position: relative;
  flex: 1;
  background: #ffffff;
  padding: 0 6.4vw;
}

.rule-title {
  font-size: 1.49rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  margin: 0;
  margin-top: 3vh;
  margin-bottom: 1vh;
}
.details {
  font-size: 1.04rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #010e4f;
  line-height: 1.64rem;
  margin: 0;
  margin-bottom: 1vh;
}
.details-min {
  margin: 0;
}
.lucky-rank {
  font-size: 1.04rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 3vh;
  img {
    width: 1.29rem;
    height: 1.29rem;
    margin-right: 0.46rem;
  }
}
</style>
